<template>
    <Layout>
    <header class="jumbotron">
      <h3>
        <strong>{{currentUser.email}}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Token:</strong>
    </p>
    <p>
      <strong>Id:</strong>
      {{currentUser.id}}
    </p>
    <p>
      <strong>Email:</strong>
      {{currentUser.email}}
    </p>
    <strong>Authorities:</strong>
    <ul>
      {{currentUser.role}}
    </ul>
</Layout>
</template>

<script>
import Layout from '../layouts/private.vue'
export default {
  name: 'Profile',
  components:{Layout},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>